import { type FC, forwardRef, ReactNode, useEffect, useState } from 'react';
import { FaForward } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { Dialogue, ModalInfo, TooltipContent } from '@/components';
import MagicWandIcon from '@/components/icons/MagicWandIcon';
import VolumeIcon from '@/components/icons/VolumeIcon';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import Shop from '@/shop/Shop';
import { COLORS } from '@/utils/colors';

import coinIcon from '../../../assets/images/icons/coin.png';
import exit from '../../../assets/images/icons/exit.png';
import question from '../../../assets/images/icons/question.png';

import { PuzzleType } from './types';

import 'tippy.js/animations/scale-subtle.css';

interface HintsPanelProps {
    puzzleType: PuzzleType;
    onLeavePuzzle: () => void;
    isReview?: boolean;
}

const HintsPanel: FC<HintsPanelProps> = ({
    puzzleType,
    onLeavePuzzle,
    isReview = false
}) => {
    const { getLabel } = useLabels();
    const [isPauseDialogVisible, setIsPauseDialogVisible] = useState(false);
    const { gameConfig } = useGameConfig();
    const {
        settings,
        updateIsMagicWandTooltipNeeded,
        updateIsAudioHintTooltipNeeded
    } = useSettings();
    const { gameProgress, addCoins } = useGameProgress();
    const { navigate } = useNavigation();

    const [isMagicWandTooltipVisible, setIsMagicWandTooltipVisible] = useState(
        gameProgress.gameLevel === 2 && settings.isMagicWandTooltipNeeded
    );
    const [isAudioHintTooltipVisible, setIsAudioHintTooltipVisible] =
        useState(false);

    const [isShowShop, setIsShowShop] = useState(false);
    const [isShowInstructions, setIsShowInstructions] = useState(false);

    useEffect(() => {
        const onAudioHintWordSelected = ({ wordId }) => {
            if (gameProgress.coins < gameConfig.audioHintPrice) {
                setIsShowShop(true);
            } else {
                emitter.emit('audioHintPlayWord', { wordId });
                addCoins(-gameConfig.audioHintPrice);
            }
        };

        const onMagicWandHintTileFound = () => {
            if (gameProgress.coins < gameConfig.magicWandHintPrice) {
                setIsShowShop(true);
            } else {
                emitter.emit('magicWandHintShowTile');
                addCoins(-gameConfig.magicWandHintPrice);
            }
        };

        const onMagicWandHintCannotFind = () => {
            emitter.emit('playSound', { sound: SoundName.Wrong });
            toast(getLabel('hint.all-letters-are-found'));
        };

        emitter.on('audioHintWordSelected', onAudioHintWordSelected);
        emitter.on('magicWandHintTileFound', onMagicWandHintTileFound);
        emitter.on('magicWandHintCannotFind', onMagicWandHintCannotFind);

        return () => {
            emitter.off('audioHintWordSelected', onAudioHintWordSelected);
            emitter.off('magicWandHintTileFound', onMagicWandHintTileFound);
            emitter.off('magicWandHintCannotFind', onMagicWandHintCannotFind);
        };
    }, [gameProgress.coins]);

    const onPausePress = () => setIsPauseDialogVisible(true);
    const onAudioHintPress = () => {
        if (
            !isAudioHintTooltipVisible &&
            gameProgress.coins >= gameConfig.audioHintPrice
        ) {
            emitter.emit('audioHintRequested');
        } else if (gameProgress.coins < gameConfig.audioHintPrice) {
            setIsShowShop(true);
        }
    };

    const onMagicWandHintPress = () => {
        if (
            !isMagicWandTooltipVisible &&
            gameProgress.coins >= gameConfig.magicWandHintPrice
        ) {
            emitter.emit('magicWandHintRequested');
        } else if (gameProgress.coins < gameConfig.magicWandHintPrice) {
            setIsShowShop(true);
        }
    };

    const onFinishStep = () => emitter.emit('forceGameStepComplete');
    const onLeavePuzzlePress = () => onLeavePuzzle();
    const onSeeNewWordsAgainPress = () => navigate('WordsIntro');
    const onHelpPress = () => setIsShowInstructions(true);

    const getInstructionsText = () => {
        switch (puzzleType) {
            case PuzzleType.BasicWithTranslation:
            case PuzzleType.BasicWithoutTranslation:
                return getLabel('puzzle.find-words');
            case PuzzleType.ChooseTranslation:
                return getLabel('puzzle.find-words-and-choose-translation');
            default:
                return 'Instructions are missing';
        }
    };

    return (
        <>
            {(isMagicWandTooltipVisible || isAudioHintTooltipVisible) && (
                <div className="fixed inset-0 z-10 bg-black opacity-70"></div>
            )}
            <div className="flex justify-between">
                <div className="flex gap-2">
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.magic-wand')}
                                text={getLabel('hint.magic-wand-text')}
                            />
                        }
                        visible={isMagicWandTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setIsMagicWandTooltipVisible(false);
                            void updateIsMagicWandTooltipNeeded(false);
                            setIsAudioHintTooltipVisible(true);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <HintButton
                            onButtonPress={onMagicWandHintPress}
                            icon={
                                <MagicWandIcon
                                    width={36}
                                    height={36}
                                    color={COLORS.white}
                                />
                            }
                            price={gameConfig.magicWandHintPrice}
                            className={isMagicWandTooltipVisible ? 'z-20' : ''}
                        />
                    </Tippy>
                    <Tippy
                        content={
                            <TooltipContent
                                title={getLabel('hint.audio-hint')}
                                text={getLabel('hint.audio-hint-text')}
                            />
                        }
                        visible={isAudioHintTooltipVisible}
                        placement="top-start"
                        onClickOutside={() => {
                            setIsAudioHintTooltipVisible(false);
                            void updateIsAudioHintTooltipNeeded(false);
                        }}
                        duration={300}
                        animation="scale-subtle"
                    >
                        <HintButton
                            onButtonPress={onAudioHintPress}
                            icon={
                                <VolumeIcon
                                    width={22}
                                    height={22}
                                    color={COLORS.white}
                                />
                            }
                            price={gameConfig.audioHintPrice}
                            className={isAudioHintTooltipVisible ? 'z-20' : ''}
                        />
                    </Tippy>
                    {settings.isShowDevControls && !isReview && (
                        <HintButton
                            onButtonPress={onFinishStep}
                            icon={<FaForward size={20} color="white" />}
                        />
                    )}
                </div>
                <div className="flex gap-2">
                    <HintButton
                        onButtonPress={onHelpPress}
                        icon={<img src={question} className="h-5" />}
                    />
                    <HintButton
                        onButtonPress={onPausePress}
                        icon={<img src={exit} className="h-5" />}
                    />
                </div>
            </div>
            <Shop
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
            <ModalInfo
                isVisible={isShowInstructions}
                onClose={() => setIsShowInstructions(false)}
                description={getInstructionsText()}
            />
            <Dialogue
                isVisible={isPauseDialogVisible}
                onClose={() => setIsPauseDialogVisible(false)}
                title={getLabel('puzzle.game-on-hold')}
                text={
                    isReview
                        ? getLabel('puzzle.are-you-sure')
                        : getLabel('puzzle.if-you-leave')
                }
                onButton1Press={onLeavePuzzlePress}
                onButton2Press={isReview ? undefined : onSeeNewWordsAgainPress}
                button1Text={getLabel('puzzle.leave-this-puzzle')}
                button2Text={
                    isReview
                        ? undefined
                        : getLabel('puzzle.see-new-words-again')
                }
            />
        </>
    );
};

interface HintButtonProps {
    onButtonPress: () => void;
    icon: ReactNode;
    price?: number;
    className?: string;
}

// eslint-disable-next-line react/display-name
const HintButton = forwardRef<HTMLButtonElement, HintButtonProps>(
    ({ onButtonPress, icon, price, className }, ref) => {
        const { vibrateLight } = useVibration();

        const handlePress = () => {
            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Click2 });
            onButtonPress();
        };

        return (
            <motion.button
                ref={ref}
                onClick={handlePress}
                className={classNames(
                    'flex h-9 w-9 items-center justify-center rounded-xl bg-orange',
                    className
                )}
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={{ scale: 1 }}
            >
                <div className="relative flex items-center justify-center">
                    {icon}
                </div>
                {price && (
                    <div className="absolute mt-12 flex h-5 items-center justify-around rounded-md bg-black/50 pr-1">
                        <img
                            src={coinIcon}
                            alt="Coin Icon"
                            className="h-4 w-4"
                        />
                        <span className="text-xs font-bold text-white">
                            {price}
                        </span>
                    </div>
                )}
            </motion.button>
        );
    }
);

export default HintsPanel;
