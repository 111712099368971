import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';

import { getLanguageByKey } from '@/utils/languages';

import prizesData from '../../assets/content/Prizes.json';
import {
    CategoriesData,
    CategoryInfoLocalized,
    WordInfoLocalized
} from '../types/category';
import { LandscapeInfoLocalized, LandscapesData } from '../types/prize';
import {
    processCategoriesData,
    processLandscapesData
} from '../utils/contentHandlers';

interface ContentContextType {
    landscapes: LandscapeInfoLocalized[];
    getLandscape: (landscapeId: string) => LandscapeInfoLocalized | null;
    categories: CategoryInfoLocalized[];
    getWordsByCategory: (categoryId: string) => WordInfoLocalized[];
    loadContent: (
        uiLanguage: string,
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => Promise<void>;
}

interface ContentProviderProps {
    children: ReactNode;
}

const ContentContext = createContext<ContentContextType | undefined>(undefined);

// Define a type for the dynamic import functions
type ImportFunction = () => Promise<{
    default: CategoriesData;
}>;

// Use import.meta.glob to define all possible categories .json files
const categoriesImporters: Record<string, ImportFunction> = import.meta.glob<{
    default: CategoriesData;
}>('../../assets/content/categories/*.json');

export const ContentProvider: FC<ContentProviderProps> = ({ children }) => {
    const [categories, setCategories] = useState<CategoryInfoLocalized[]>([]);
    const [landscapes, setLandscapes] = useState<LandscapeInfoLocalized[]>([]);

    // Optional: Implement caching for loaded categories json
    const categoriesCache: Record<string, CategoriesData> = {};

    const loadContent = useCallback(
        async (
            uiLanguage: string,
            studyLanguage: string,
            onLoadingFinished: () => void
        ) => {
            console.log('Loading content...');

            if (!uiLanguage || !studyLanguage) {
                console.log('Language information is not available yet');
                onLoadingFinished();
                return;
            }

            // Validate the study language
            const selectedLanguage = getLanguageByKey(studyLanguage);
            if (!selectedLanguage) {
                console.error(`Unsupported study language: ${studyLanguage}`);
                onLoadingFinished();
                return;
            }

            // Validate the ui language
            const selectedUiLanguage = getLanguageByKey(uiLanguage);
            if (!selectedUiLanguage) {
                console.error(`Unsupported UI language: ${uiLanguage}`);
                onLoadingFinished();
                return;
            }

            try {
                let categoriesData: CategoriesData;

                if (categoriesCache[studyLanguage]) {
                    // Use cached data
                    categoriesData = categoriesCache[studyLanguage];
                    console.log(
                        `Loaded categories json for '${studyLanguage}' from cache.`
                    );
                } else {
                    // Construct the path based on studyLanguage
                    const importPath = `../../assets/content/categories/${studyLanguage}.json`;

                    const importer = categoriesImporters[importPath];

                    if (!importer) {
                        throw new Error(
                            `Categories json file not found for study language: ${studyLanguage}`
                        );
                    }

                    // Dynamically import the Categories json
                    const categoriesModule = await importer();
                    categoriesData = categoriesModule.default;

                    // Cache the loaded data
                    categoriesCache[studyLanguage] = categoriesData;
                    console.log(
                        `Loaded categories json for '${studyLanguage}' and cached.`
                    );
                }

                // Process and set categories with cleaned audioUrls
                const processedCategories = processCategoriesData(
                    categoriesData,
                    uiLanguage,
                    studyLanguage
                );
                setCategories(processedCategories);

                // Process Prizes.json as a single, static file
                const processedLandscapes = processLandscapesData(
                    prizesData as LandscapesData,
                    uiLanguage,
                    studyLanguage
                );
                setLandscapes(processedLandscapes);

                console.log('Loading content finished');
            } catch (error) {
                console.error('Error loading content:', error);
            } finally {
                onLoadingFinished();
            }
        },
        []
    );

    const getLandscape = useCallback(
        (landscapeId: string): LandscapeInfoLocalized | null => {
            return (
                landscapes.find(landscape => landscape.id === landscapeId) ||
                null
            );
        },
        [landscapes]
    );

    const getWordsByCategory = useCallback(
        (categoryId: string): WordInfoLocalized[] => {
            const category = categories.find(cat => cat.id === categoryId);
            return category ? category.content : [];
        },
        [categories]
    );

    const contextValue = useMemo(
        () => ({
            loadContent,
            landscapes,
            categories,
            getLandscape,
            getWordsByCategory
        }),
        [loadContent, landscapes, categories, getLandscape, getWordsByCategory]
    );

    return (
        <ContentContext.Provider value={contextValue}>
            {children}
        </ContentContext.Provider>
    );
};

export const useContent = (): ContentContextType => {
    const context = useContext(ContentContext);
    if (context === undefined) {
        throw new Error('useContent must be used within a ContentProvider');
    }
    return context;
};
