import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useState
} from 'react';

import {
    getAllProgressApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateSelectedLandscapeIdApi,
    updateWordsProgressApi,
    updateXpApi
} from '@/api/client';
import { useContent } from '@/context';
import { parseDate } from '@/utils/date';

import { CategoryInfoWithProgress, WordInfoLocalized } from '../types/category';
import { LandscapeInfoLocalized } from '../types/prize';
import {
    CategoryProgressItem,
    GameProgressValues,
    LandscapeProgressItem,
    LandscapesUnlocked,
    WordProgressItem
} from '../types/progress';
import {
    getCategoriesToChooseFrom,
    getUniqueCleanWords,
    getWordsToStudyForCurrentCategory
} from '../utils/categories';
import { Random } from '../utils/Random';
import {
    getAllWordsInProgress,
    getAllWordsToReview,
    getAllWordsToReviewCount
} from '../utils/review';

interface GameProgressContextType {
    categoriesToChooseFrom?: CategoryInfoWithProgress[];
    chooseCategory: (category: CategoryInfoWithProgress) => void;
    resetCategories: () => void;
    currentCategory?: CategoryInfoWithProgress;
    gameProgress: GameProgress;
    getWordsByLevel: (level: number) => WordInfoLocalized[];
    getWordsLearnedCount: () => number;
    getWordsToReview: (studyLanguage: string) => WordInfoLocalized[];
    getWordsToReviewCount: () => number;
    goToNextGameLevel: (studyLanguage: string) => void;
    isLockedLandscapeAvailable: () => boolean;
    isNoWordsToReviewWithSpacedRep: boolean;
    loadCategoriesToChooseFrom: (languageLevel: string) => void;
    loadGameProgress: (
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => Promise<void>;
    nextLandscape?: LandscapeInfoLocalized;
    prepareNextLandscape: () => void;
    resetNextLandscape: () => void;
    unlockCategory: (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => Promise<void>;
    unlockLandscape: (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => Promise<void>;
    updateReviewProgress: (
        studyLanguage: string,
        reviewedWords: WordInfoLocalized[]
    ) => Promise<void>;
    updateCategoriesProgress: (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => Promise<void>;
    updateSelectedLandscapeId: (
        studyLanguage: string,
        landscapeId: string
    ) => void;
    addXp: (studyLanguage: string, xp: number) => Promise<void>;
    addCoins: (coins: number) => Promise<void>;
    updateIsNoWordsToReviewWithSpacedRep: (
        noWordsToReviewWithSpacedRep: boolean
    ) => void;
    wordsToStudy?: WordInfoLocalized[];
}

export interface GameProgress extends GameProgressValues {
    categoriesProgress: CategoryInfoWithProgress[];
    landscapesProgress: LandscapeProgressItem[];
    landscapesUnlocked: LandscapesUnlocked;
}

interface GameProgressProviderProps {
    children: ReactNode;
}

const GameProgressContext = createContext<GameProgressContextType | undefined>(
    undefined
);

export const GameProgressProvider: FC<GameProgressProviderProps> = ({
    children
}) => {
    const { categories, landscapes } = useContent();

    const [gameProgress, setGameProgress] = useState<GameProgress>();
    const [isNoWordsToReviewWithSpacedRep, setIsNoWordsToReviewWithSpacedRep] =
        useState(false);

    const [categoriesToChooseFrom, setCategoriesToChooseFrom] =
        useState<CategoryInfoWithProgress[]>();
    const [currentCategory, setCurrentCategory] =
        useState<CategoryInfoWithProgress>();
    const [wordsToStudy, setWordsToStudy] = useState<WordInfoLocalized[]>();

    const [nextLandscape, setNextLandscape] =
        useState<LandscapeInfoLocalized>();

    const processCategoriesProgress = (
        allCategoryProgress: CategoryProgressItem[]
    ): CategoryInfoWithProgress[] => {
        const categoriesWithProgress: CategoryInfoWithProgress[] = [];

        console.log('Total amount of categories: ' + categories.length);

        for (const category of categories) {
            // Find the progress for the current category from the fetched data
            let categoryProgressItem: CategoryProgressItem =
                allCategoryProgress.find(
                    progressItem => progressItem.categoryId === category.id
                );

            // If no progress is found for the category, initialize it
            if (!categoryProgressItem) {
                categoryProgressItem = {
                    categoryId: category.id,
                    wordProgressItems: [] as WordProgressItem[],
                    unlocked: false
                };
            }

            // Normalize dates
            categoryProgressItem.lastTimePlayed = parseDate(
                categoryProgressItem.lastTimePlayed
            );
            categoryProgressItem.unlockedAt = parseDate(
                categoryProgressItem.unlockedAt
            );
            categoryProgressItem.unlocked =
                !!categoryProgressItem.unlocked ||
                categoryProgressItem.unlockedAt !== undefined;

            // Normalize word progress item dates
            categoryProgressItem.wordProgressItems =
                categoryProgressItem.wordProgressItems.map(wpi => ({
                    ...wpi,
                    firstTimePlayed: parseDate(wpi.firstTimePlayed),
                    lastTimePlayed: parseDate(wpi.lastTimePlayed),
                    movedToBoxAt: parseDate(wpi.movedToBoxAt)
                }));

            // Build CategoryInfoWithProgress structure
            const categoryInfoWithProgress: CategoryInfoWithProgress = {
                categoryInfo: category,
                categoryProgressItem
            };

            // Filter word progress to only include words that still exist in the category's content
            categoryInfoWithProgress.categoryProgressItem.wordProgressItems =
                categoryInfoWithProgress.categoryProgressItem.wordProgressItems.filter(
                    wordProgressItem =>
                        category.content.some(
                            wordInfo => wordInfo.id === wordProgressItem.wordId
                        )
                );

            categoriesWithProgress.push(categoryInfoWithProgress);
        }

        console.log(
            'loadCategoriesProgress finished. Amount of categoriesWithProgress: ' +
                categoriesWithProgress.length
        );

        return categoriesWithProgress;
    };

    const loadGameProgress = useCallback(
        async (studyLanguage: string, onLoadingFinished: () => void) => {
            console.log('Loading game progress...');

            resetCurrentValues();

            try {
                const {
                    landscapesProgress,
                    categoriesProgress,
                    languageProgress
                } = await getAllProgressApi(studyLanguage);

                const allCategoriesProgress =
                    processCategoriesProgress(categoriesProgress);

                setGameProgress({
                    gameLevel: languageProgress.gameLevel,
                    coins: languageProgress.coins,
                    xp: languageProgress.xp,
                    landscapeProgress: languageProgress.landscapeProgress,
                    selectedLandscapeId: languageProgress.selectedLandscapeId,
                    categoriesProgress: allCategoriesProgress,
                    landscapesProgress: landscapesProgress,
                    landscapesUnlocked: {
                        unlocked: landscapesProgress.length,
                        total: landscapes.length
                    }
                });

                console.log('Game progress loaded successfully');
                onLoadingFinished();
            } catch (error) {
                console.error('Error loading game progress:', error);
                throw new Error('Failed to load game progress');
            }
        },
        [categories, landscapes]
    );

    const loadCategoriesToChooseFrom = (languageLevel: string) => {
        console.log('loadCategoriesToChooseFrom');
        console.log(
            'categoriesProgress items: ' +
                gameProgress.categoriesProgress.length
        );

        const categories = getCategoriesToChooseFrom(
            gameProgress.categoriesProgress,
            languageLevel
        );
        setCategoriesToChooseFrom(categories);
    };

    const unlockCategory = async (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => {
        const categoriesProgress = gameProgress.categoriesProgress;
        const categoryProgress = categoriesProgress.find(
            cp => cp.categoryInfo.id === categoryId
        );

        if (categoryProgress) {
            categoryProgress.categoryProgressItem.unlocked = true;
            categoryProgress.categoryProgressItem.unlockedAt = new Date();

            await updateWordsProgressApi(studyLanguage, [
                categoryProgress.categoryProgressItem
            ]);

            setGameProgress(prev => ({ ...prev, categoriesProgress }));
        } else {
            console.error('Category progress is for some reason doesn’t exist');
        }

        // update categories to choose from to reflect the unlocked category
        loadCategoriesToChooseFrom(languageLevel);
    };

    const chooseCategory = (category: CategoryInfoWithProgress) => {
        setCurrentCategory(category);
        setWordsToStudy(getWordsToStudyForCurrentCategory(category));
    };

    const updateSelectedLandscapeId = async (
        studyLanguage: string,
        landscapeId: string
    ) => {
        await updateSelectedLandscapeIdApi(studyLanguage, landscapeId);
        setGameProgress(prevGameProgress => ({
            ...prevGameProgress,
            selectedLandscapeId: landscapeId
        }));
    };

    const addXp = async (studyLanguage: string, xp: number) => {
        const updatedXp = gameProgress.xp + xp;
        await updateXpApi(studyLanguage, updatedXp);
        setGameProgress(prev => ({ ...prev, xp: updatedXp }));
    };

    const getWordsLearnedCount = () => {
        return gameProgress.categoriesProgress.reduce(
            (totalWordsLearned, categoryProgress) => {
                return (
                    totalWordsLearned +
                    categoryProgress.categoryProgressItem.wordProgressItems
                        .length
                );
            },
            0
        );
    };

    const addCoins = async (coins: number) => {
        const updatedCoins = gameProgress.coins + coins;
        await updateCoinsApi(updatedCoins);
        setGameProgress(prev => ({ ...prev, coins: updatedCoins }));
    };

    const resetCategories = () => {
        setCategoriesToChooseFrom(undefined);
        setCurrentCategory(undefined);
    };

    const resetCurrentValues = () => {
        resetCategories();
        setWordsToStudy(undefined);
        setNextLandscape(undefined);
    };

    const updateReviewProgress = useCallback(
        async (studyLanguage: string, correctWords: WordInfoLocalized[]) => {
            if (!gameProgress) {
                console.error('GameProgress is not loaded yet.');
                return;
            }

            const currentTime = new Date();

            // Create a Map for efficient access and immutability
            const updatedCategoriesMap = new Map<
                string,
                CategoryInfoWithProgress
            >(
                gameProgress.categoriesProgress.map(cp => [
                    cp.categoryInfo.id,
                    { ...cp }
                ])
            );

            // Map to store updated word progress items by category
            const updatedWordProgressItemsByCategory = new Map<
                string,
                WordProgressItem[]
            >();

            for (const word of correctWords) {
                const categoryId = word.categoryId;
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId);

                if (categoryInfoWithProgress) {
                    const categoryProgressItem =
                        categoryInfoWithProgress.categoryProgressItem;

                    // Find existing word progress
                    const existingWordProgressIndex =
                        categoryProgressItem.wordProgressItems.findIndex(
                            wpi => wpi.wordId === word.id
                        );

                    if (existingWordProgressIndex !== -1) {
                        // Update existing word progress item immutably
                        const existingWordProgress =
                            categoryProgressItem.wordProgressItems[
                                existingWordProgressIndex
                            ];
                        const updatedWordProgress: WordProgressItem = {
                            ...existingWordProgress,
                            box: existingWordProgress.box + 1,
                            movedToBoxAt: currentTime
                        };

                        // Update word progress items immutably
                        categoryProgressItem.wordProgressItems =
                            categoryProgressItem.wordProgressItems.map(wpi =>
                                wpi.wordId === word.id
                                    ? updatedWordProgress
                                    : wpi
                            );

                        // Add the updated word progress to the map
                        if (
                            !updatedWordProgressItemsByCategory.has(categoryId)
                        ) {
                            updatedWordProgressItemsByCategory.set(
                                categoryId,
                                []
                            );
                        }
                        updatedWordProgressItemsByCategory
                            .get(categoryId)!
                            .push(updatedWordProgress);
                    }
                }
            }

            // Prepare data to send to API (CategoryProgressItem[]) and include only updated word progress items
            const progressItemsToUpdate: CategoryProgressItem[] = Array.from(
                updatedWordProgressItemsByCategory.entries()
            ).map(([categoryId, updatedWordProgressItems]) => {
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId)!;
                return {
                    categoryId: categoryId,
                    lastTimePlayed:
                        categoryInfoWithProgress.categoryProgressItem
                            .lastTimePlayed,
                    unlocked:
                        categoryInfoWithProgress.categoryProgressItem.unlocked,
                    wordProgressItems: updatedWordProgressItems
                };
            });

            if (progressItemsToUpdate.length > 0) {
                try {
                    await updateWordsProgressApi(
                        studyLanguage,
                        progressItemsToUpdate
                    );
                    console.log('Review status updated in the API.');
                } catch (error) {
                    console.error(
                        'Error updating review status in the API:',
                        error
                    );
                    throw error;
                }
            }

            // Update the state immutably with all categoriesProgress
            const updatedCategoryProgressItems: CategoryInfoWithProgress[] =
                Array.from(updatedCategoriesMap.values());

            setGameProgress(prev => ({
                ...prev!,
                categoriesProgress: updatedCategoryProgressItems
            }));

            console.log('Review status updated');
        },
        [gameProgress]
    );

    const updateCategoriesProgress = async (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => {
        if (!gameProgress) {
            console.error('GameProgress is not loaded yet.');
            return;
        }

        // Extract unique learned words to prevent processing duplicates
        const uniqueLearnedWords = Array.from(
            new Set(learnedWords.map(word => word.id))
        )
            .map(id => learnedWords.find(word => word.id === id))
            .filter((word): word is WordInfoLocalized => word !== undefined);

        if (uniqueLearnedWords.length === 0) {
            console.log('No unique learned words to process.');
            return;
        }

        const currentTime = new Date();

        // Create a Map for efficient access and immutability
        const updatedCategoriesMap = new Map<string, CategoryInfoWithProgress>(
            gameProgress.categoriesProgress.map(cp => [
                cp.categoryInfo.id,
                { ...cp }
            ])
        );

        // Set to track which categories have been modified
        const modifiedCategoryIds = new Set<string>();

        // Map to store updated word progress items by category
        const updatedWordProgressItemsByCategory = new Map<
            string,
            WordProgressItem[]
        >();

        for (const word of uniqueLearnedWords) {
            const categoryId = word.categoryId;
            let categoryInfoWithProgress = updatedCategoriesMap.get(categoryId);

            // If the category progress does not exist, create a new CategoryInfoWithProgress
            if (!categoryInfoWithProgress) {
                const categoryInfo = categories.find(c => c.id === categoryId);
                if (!categoryInfo) {
                    console.warn(`Category with id ${categoryId} not found.`);
                    continue; // Skip if category info is missing
                }

                categoryInfoWithProgress = {
                    categoryInfo: categoryInfo,
                    categoryProgressItem: {
                        categoryId: categoryId,
                        lastTimePlayed: currentTime,
                        wordProgressItems: [],
                        unlocked: true
                    }
                };

                updatedCategoriesMap.set(categoryId, categoryInfoWithProgress);
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Created new category progress for categoryId: ${categoryId}`
                );
            }

            // Update last time played only for the current category
            if (
                currentCategory &&
                currentCategory.categoryInfo.id === categoryId
            ) {
                categoryInfoWithProgress.categoryProgressItem.lastTimePlayed =
                    currentTime;
                categoryInfoWithProgress.categoryProgressItem.unlocked = true;
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated lastTimePlayed for currentCategoryId: ${categoryId}`
                );
            }

            const categoryProgressItem =
                categoryInfoWithProgress.categoryProgressItem;

            // Find existing word progress
            const existingWordProgressIndex =
                categoryProgressItem.wordProgressItems.findIndex(
                    wpi => wpi.wordId === word.id
                );

            let updatedWordProgress: WordProgressItem;

            if (existingWordProgressIndex === -1) {
                // Add new word progress item
                updatedWordProgress = {
                    wordId: word.id,
                    level: gameProgress.gameLevel,
                    firstTimePlayed: currentTime,
                    lastTimePlayed: currentTime,
                    timesPlayed: 1,
                    box: 0,
                    movedToBoxAt: currentTime
                };
                categoryProgressItem.wordProgressItems = [
                    ...categoryProgressItem.wordProgressItems,
                    updatedWordProgress
                ];
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Added new WordProgressItem for wordId: ${word.id} in categoryId: ${categoryId}`
                );
            } else {
                // Update existing word progress item immutably
                const existingWordProgress =
                    categoryProgressItem.wordProgressItems[
                        existingWordProgressIndex
                    ];
                updatedWordProgress = {
                    ...existingWordProgress,
                    lastTimePlayed: currentTime,
                    timesPlayed: existingWordProgress.timesPlayed + 1
                };
                categoryProgressItem.wordProgressItems =
                    categoryProgressItem.wordProgressItems.map(wpi =>
                        wpi.wordId === word.id ? updatedWordProgress : wpi
                    );
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated WordProgressItem for wordId: ${word.id} in categoryId: ${categoryId}`
                );
            }

            // Add the updated word progress to the map
            if (!updatedWordProgressItemsByCategory.has(categoryId)) {
                updatedWordProgressItemsByCategory.set(categoryId, []);
            }
            updatedWordProgressItemsByCategory
                .get(categoryId)!
                .push(updatedWordProgress);
        }

        // Prepare data to send to API (CategoryProgressItem[]) and include only updated word progress items
        const progressItemsToUpdate: CategoryProgressItem[] = Array.from(
            updatedWordProgressItemsByCategory.entries()
        ).map(([categoryId, updatedWordProgressItems]) => {
            const categoryInfoWithProgress =
                updatedCategoriesMap.get(categoryId)!;
            return {
                categoryId: categoryId,
                lastTimePlayed:
                    categoryInfoWithProgress.categoryProgressItem
                        .lastTimePlayed,
                unlocked:
                    categoryInfoWithProgress.categoryProgressItem.unlocked,
                wordProgressItems: updatedWordProgressItems
            };
        });

        if (progressItemsToUpdate.length === 0) {
            console.log('No categories to update in the API.');
        } else {
            try {
                await updateWordsProgressApi(
                    studyLanguage,
                    progressItemsToUpdate
                );
                console.log(
                    'Updated category progress successfully in the API.'
                );
            } catch (error) {
                console.error(
                    'Error updating category progress in the API:',
                    error
                );
                throw error;
            }
        }

        // Update the state immutably with all categoriesProgress
        const updatedCategoryProgressItems: CategoryInfoWithProgress[] =
            Array.from(updatedCategoriesMap.values());

        setGameProgress(prev => ({
            ...prev,
            categoriesProgress: updatedCategoryProgressItems
        }));

        console.log('Finish updateCategoriesProgress');
    };

    const isLockedLandscapeAvailable = (): boolean => {
        return landscapes.length > gameProgress.landscapesProgress.length;
    };

    const prepareNextLandscape = () => {
        const random = new Random();
        const shuffledLandscapes = [...landscapes].sort(
            () => random.nextDouble() - 0.5
        );

        const landscapeToReturn = shuffledLandscapes.find(
            landscape =>
                !gameProgress.landscapesProgress.some(
                    lp => lp.landscapeId === landscape.id
                )
        );

        if (landscapeToReturn) {
            // TODO: download landscape image
            setNextLandscape(landscapeToReturn);
            console.log('Next landscape: ' + landscapeToReturn.id);
        }
    };

    const unlockLandscape = async (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => {
        const newLandscapesProgressItem = {
            landscapeId: landscape.id,
            addedAt: new Date()
        };
        const newLandscapesProgress = [
            ...gameProgress.landscapesProgress,
            newLandscapesProgressItem
        ];

        try {
            await Promise.all([
                updateSelectedLandscapeIdApi(studyLanguage, landscape.id),
                updateLandscapeProgressApi(studyLanguage, 0),
                updateLandscapesProgressApi(
                    studyLanguage,
                    newLandscapesProgressItem
                )
            ]);

            setGameProgress(prev => ({
                ...prev,
                landscapeProgress: 0,
                selectedLandscapeId: landscape.id,
                landscapesProgress: newLandscapesProgress,
                landscapesUnlocked: {
                    unlocked: newLandscapesProgress.length,
                    total: landscapes.length
                }
            }));
        } catch (error) {
            console.error('Error unlocking landscape:', error);
            throw new Error('Failed to unlock landscape');
        }
    };

    const resetNextLandscape = () => {
        setNextLandscape(undefined);
    };

    const getWordsByLevel = (level: number): WordInfoLocalized[] => {
        if (level < 1 || level > gameProgress.gameLevel) return [];

        const wordsByLevel: WordInfoLocalized[] = [];

        gameProgress.categoriesProgress.forEach(categoryProgress => {
            categoryProgress.categoryProgressItem.wordProgressItems.forEach(
                wordProgressItem => {
                    if (wordProgressItem.level === level) {
                        const word = categoryProgress.categoryInfo.content.find(
                            wordInfo => wordInfo.id === wordProgressItem.wordId
                        );
                        if (word) {
                            wordsByLevel.push(word);
                        }
                    }
                }
            );
        });

        return wordsByLevel;
    };

    const updateIsNoWordsToReviewWithSpacedRep = (
        noWordsToReviewWithSpacedRep: boolean
    ) => {
        setIsNoWordsToReviewWithSpacedRep(noWordsToReviewWithSpacedRep);
    };

    const getWordsToReview = (studyLanguage: string) => {
        let reviewableWords: WordInfoLocalized[] = [];

        if (isNoWordsToReviewWithSpacedRep) {
            reviewableWords = getAllWordsInProgress(
                gameProgress.categoriesProgress
            );
        } else {
            reviewableWords = getAllWordsToReview(
                gameProgress.categoriesProgress
            );
        }

        // limit up to 20 words
        return getUniqueCleanWords(reviewableWords.slice(0, 20), studyLanguage);
    };

    const getWordsToReviewCount = () => {
        return getAllWordsToReviewCount(gameProgress.categoriesProgress);
    };

    const goToNextGameLevel = async (studyLanguage: string) => {
        let updatedLandscapeProgress = 0;

        if (gameProgress.gameLevel >= 2 && isLockedLandscapeAvailable()) {
            updatedLandscapeProgress = gameProgress.landscapeProgress + 1;

            await updateLandscapeProgressApi(
                studyLanguage,
                updatedLandscapeProgress
            );
        }

        const newGameLevel = gameProgress.gameLevel + 1;
        await updateGameLevelApi(studyLanguage, newGameLevel);

        setGameProgress(prev => ({
            ...prev,
            gameLevel: newGameLevel,
            landscapeProgress: updatedLandscapeProgress
        }));
    };

    return (
        <GameProgressContext.Provider
            value={{
                categoriesToChooseFrom,
                chooseCategory,
                currentCategory,
                resetCategories,
                gameProgress,
                getWordsByLevel,
                getWordsLearnedCount,
                getWordsToReview,
                getWordsToReviewCount,
                goToNextGameLevel,
                isLockedLandscapeAvailable,
                isNoWordsToReviewWithSpacedRep,
                loadCategoriesToChooseFrom,
                loadGameProgress,
                nextLandscape,
                prepareNextLandscape,
                resetNextLandscape,
                unlockCategory,
                unlockLandscape,
                updateReviewProgress,
                updateCategoriesProgress,
                updateSelectedLandscapeId,
                addXp,
                addCoins,
                updateIsNoWordsToReviewWithSpacedRep,
                wordsToStudy
            }}
        >
            {children}
        </GameProgressContext.Provider>
    );
};

export const useGameProgress = (): GameProgressContextType => {
    const context = useContext(GameProgressContext);
    if (context === undefined) {
        throw new Error(
            'useGameProgress must be used within a GameProgressProvider'
        );
    }
    return context;
};
